<template>
  <div style="padding: 20px; text-align: left">
    <h1 style="text-align: center">用户隐私协议</h1>
    <h4 style="text-align: end; padding-right: 10px">
      更新日期：2024年1月16日
    </h4>
    <h4 style="text-align: end; padding-right: 10px">
      生效日期：2024年1月16日
    </h4>
    <p style="text-indent: 1.3rem">
      我们尊重并保护所有使用熊猫点钢APP（注意后面简称本产品）服务用户的个人隐私权，本产品由钢宝电子商务有限公司开发并运营。为了给您提供更准确的服务，会按照本隐私协议的规定使用和披露您的个人信息。我们将以高度的勤勉、审慎义务对待这些信息。除本隐私协议另有规定外，在未征得您事先许可的情况下，本产品不会将这些信息对外披露或向第三方提供。本产品将不时更新本隐私协议。您在同意本产品服务使用协议之时，即视为您已经同意本隐私协议全部内容。本隐私协议属于本产品用户使用协议不可分割的一部分。
    </p>
    <p>1.适用范围</p>
    <p>
      a)在您注册本产品帐号时，您根据本产品要求提供的个人注册信息（商家应法律法规要求需公示的业名称及相关工商注册信息除外）；
    </p>
    <p>
      b)在您使用熊猫点钢APP服务，或访问熊猫点钢APP时，熊猫点钢APP自动接收并记录的您的浏览反馈信息，包括但不限于您的下单信息、充值信息、访问日期和时间等数据；
    </p>
    <p>2.信息使用</p>
    <p>
      a)本产品不会向任何第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本产品（含本产品关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
    </p>
    <p>
      b)本产品亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本产品平台用户如从事上述活动，一经发现，本产品有权立即终止与该用户的服务协议。
    </p>
    <p>3.信息披露</p>
    <p style="text-indent: 1.3rem">
      在如下情况下，本产品将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
    </p>
    <p>a)经您事先同意，向第三方披露；</p>
    <p>b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
    <p>
      c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
    </p>
    <p>
      d)如您出现违反中国有关法律、法规或者本产品服务协议或相关规则的情况，需要向第三方披露；
    </p>
    <p>
      e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
    </p>
    <p>f)其它本产品根据法律、法规政策做认为合适的披露。</p>
    <p>4.信息存储和交换</p>
    <p style="text-indent: 1.3rem">
      本产品收集的有关您的信息和资料将保存在本产品及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本产品收集信息和资料所在地的境外并在境外被访问、存储和展示。
    </p>
    <p>5.信息安全</p>
    <p>
      a)本产品帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本产品将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
    </p>
    <p>
      b)在使用本产品服务进行交易时，您不可避免的要向平台透露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向平台提供。如您发现自己的个人信息泄密，尤其是本产品用户名及密码发生泄露，请您立即联络本产品客服，以便采取相应措施
    </p>
    <p>
      c)本产品收集到的您的有关信息数据，包括但不限于参与活动、成交信息及充值详情；
    </p>
    <p style="font-weight: bold">6.应用获取的敏感信息</p>
    <p style="font-weight: bold">
      a)获取用户的软件安装列表：目的是进行应用间跳转，不做任何信息保存；
    </p>
    <p style="font-weight: bold">
      b)获取用户手机号：目的是作为用户快捷登录已经设备标识；
    </p>
    <p style="font-weight: bold">
      c)获用户MAC地址：目的是获取用户设备唯一标识，用于推送以及账号登录所需；
    </p>
    <p style="font-weight: bold">
      d)获取用户的ANDROID ID、IMEI、MEID：目的需要获取唯一标识，用于推送验证；
    </p>
    <p style="font-weight: bold">
      e)获取用户存储权限：目的需要下载软件更新包以及保存必须的资源文件。
    </p>
    <p style="font-weight: bold">
      f)获取相机权限：目的用于上传用户头像以及企业认证时上传认证信息对应的图片。
    </p>
    <p>7.第三方软件开发包(SDK)个人信息收集情况</p>
    <p>
      a)极光推送：极光推送服务为APP赋予消息和通知的推送能力。您的APP在集成极光推送SDK后，极光推送SDK会收集使用实现服务所必须的个人信息，通过加密的通道将消息和通知推送给您的终端用户。极光推送SDK收集使用的个人信息包括：①设备信息：用于识别唯一用户，保证消息推送的精准送达；优化推送通道资源，我们会根据设备上不同APP的活跃情况，整合消息推送的通道资源，为开发者提高消息送达率；为开发者提供智能标签以及展示业务统计信息的服务；②网络信息与位置信息：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域推送的功能。
    </p>
    <p>8.自启动和关联启动说明</p>
    <p>
      a)为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。
    </p>
    <p>
      b)当您打开熊猫点钢app内容类推送消息，在征得您的明确同意后，会跳转熊猫点钢app打开相关内容。在未征得您同意的情况下，则不会有关联启动。
    </p>
    <p>
      9.熊猫点钢为您提供账户注销功能，您可以通过该注销功能注销您的账户。我们在此善意的提醒您，注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的用户信息我们只会在熊猫点钢前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。根据法律法规规定，相关交易记录可能须在平台后台保存3年甚至更长时间。经熊猫点钢确认您的账户同时满足以下条件的，您的账户将被注销：
    </p>
    <p>a）账户无任何纠纷，包括投诉举报和被投诉举报；</p>
    <p>b）账户为正常使用中的账户且无任何账户被限制的记录；</p>
    <p>c）账户内无未完成的订单、服务、无需要补齐的信用额度；</p>
    <p>10.本隐私协议的更改</p>
    <p>
      a)如果决定更改隐私协议，我们会在本协议中，以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
    </p>
    <p>
      b)保留随时修改本协议的权利，因此请经常查看。如对本协议作出重大更改，会发布在官网新闻处。
    </p>
    <p>11.联系我们</p>
    <p style="text-indent: 1.3rem">
      如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过客服电话：400-997-1366
      或者【750953693@qq.com】与我们联系，我们核查后会在【15】个工作日或者法律法规要求的时限内反馈您的投诉与举报。
    </p>
  </div>
</template>

<style type="text/css" scoped>
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0px;
  padding: 0px;
}
body {
  font-family: "Arial Unicode MS";
  font-size: 16px;
  margin: 0 auto;
  padding: 10px 2%;
  width: 96%;
}
h1 {
  font-size: 20px;
  margin: 10px auto;
  line-height: 40px;
  color: #000;
  text-align: center;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin: 15px 0;
}
h3 {
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  margin: 20px 0 10px 0;
}
</style>
<script>
export default {
  // 数据对象
  data() {
    return {};
  },
  // 函数集合
  methods: {},
};
</script>
